/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
const removeListeners = (el) => {
    // only replace the ancestor element
    const clone = el.cloneNode(false);
    // copy children backwards because of the removal
    for (let index = el.childNodes.length - 1; index >= 0; --index) {
        clone.insertBefore(el.childNodes[index], clone.firstChild);
    }
    // insert back into DOM
    el.parentNode.replaceChild(clone, el);
};
let $moving = false;
let keyPressed = {};
const drag = (e) => {
    const el = document.getElementById('phrase-editor');
    // Positions cursor in center of element when being dragged, as opposed to the top left
    const width = el.offsetWidth / 2;
    const height = el.offsetHeight / 2;
    // Element follows mouse cursor
    if ($moving) {
        // Position element, minus half width/height as above
        const x = e.clientX - width;
        const y = e.clientY - height;
        // Store left and top in variable
        const position = `left:${x}px;top:${y}px`;
        // Set style
        el.setAttribute('style', position);
    }
};
const onKeyDown = (e) => {
    const el = document.getElementById('phrase-editor');
    keyPressed[e.key + e.location] = true;
    if (keyPressed.Alt1 === true && keyPressed.p0 === true) {
        // Left alt+p pressed!
        if (el.style.display === '') {
            el.setAttribute('style', 'display:none !important;');
            keyPressed = {};
        }
        else {
            el.style.display = '';
            keyPressed = {}; // reset key map
        }
    }
    // Enable Drag
    if (keyPressed.Shift2 === true) {
        $moving = !$moving;
        keyPressed = {};
    }
};
// Destroy drag on mouse up
const end = () => {
    $moving = false;
};
const enableDrag = (el) => {
    // Bind functions to events
    el.addEventListener('mousemove', drag);
    el.addEventListener('onkeydown', end);
};
const disableDrag = (el) => {
    el.removeEventListener('mousemove', drag);
    el.removeEventListener('onkeydown', end);
};
const addPhraseControls = () => {
    document.addEventListener('keydown', onKeyDown);
};
const loadPhraseStyleFixes = () => {
    const style = document.createElement('style');
    style.innerHTML = String.raw `
  .phrase-label {
    margin-left: 25px !important;
  }

  #phrase-editor {
    display: flex;
    display: unset !important;
    top: 400px;
    left: 600px;
    right: unset !important;
    bottom: unset !important;
    height: unset !important;
    min-height: 305px;
    width: 600px;
    #phrase-editor-toggle {
      display: none !important;
    }
    #phrase-editor-inner {
      display: flex;
      flex-direction: column;
      #phrase-translation-list-wrapper {
        margin-top:10px;
      }
      .phrase-container-fluid {
          margin-right: unset !important;
          margin-left: unset !important;
          padding-left: 10px !important;
          padding-right: 15px !important;
          padding-bottom: 10px;
          height: unset !important;
      }
      .navbar-form{
        float: unset !important;
      }
    }
    .phrase-col-xs-8{
      .phrase-row{
        display:flex;
        &:nth-child(1){
          flex-direction: row;
          .phrase-col-xs-6{
            width: unset;
          }
        }
        &:nth-child(2){
          flex-direction: column;
          .phrase-col-xs-6{
            width: unset;
          }
        }
      }
    }
  }
  #phrase-editor-content-wrapper {
    height: unset !important;
  }
  /* iPhone 5 & 5S in portrait */
  @media only screen
  and (max-device-width : 568px)
  and (orientation : portrait) {
    #phrase-editor {
      width: auto;
        #phrase-editor-inner{
          .phrase-container-fluid{
            padding-bottom: 0 !important;
          }

        }
      }
      .phrase-container-fluid{

      .phrase-navbar-brand {
        display:none;
      }
      .phrase-col-xs-8{
        width: unset !important;
        float:none !important;
      }
      .tab-content{
        padding-top: 10px;
      }
    }

    #phrase-editor-content-wrapper{
      .phrase-col-xs-8 > div:nth-child(1){
        display: none;
      }
      .phrase-col-xs-8 > div:nth-child(2) > div:nth-child(1) {display: none;}
    }
    .phrase-col-xs-4 {
      display: none;
    }
  }`;
    const ref = document.querySelector('script');
    // Insert our new styles before the first script tag
    ref.parentNode.insertBefore(style, ref);
};
export const addPhraseApp = () => {
    window.PHRASEAPP_ENABLED = true;
    window.PHRASEAPP_CONFIG = {
        projectId: '1829a04a4f4f95914a14aab3d0f32a74',
        fullReparse: true,
        prefix: '{{__',
        suffix: '__}}',
        debugMode: true
    };
    // Fix Style issues
    loadPhraseStyleFixes();
    const phraseApp = document.createElement('script');
    phraseApp.type = 'text/javascript';
    phraseApp.async = true;
    phraseApp.className = 'phraseAppCode';
    phraseApp.src = [
        'https://',
        'phraseapp.com/assets/in-context-editor/2.0/app.js?',
        new Date().getTime()
    ].join('');
    const s = document.getElementsByTagName('script')[0];
    if (s != undefined) {
        s.parentNode.insertBefore(phraseApp, s);
    }
    else {
        document.insertBefore(phraseApp, null);
    }
    const checkExist = setInterval(() => {
        const divPhraseEditor = document.getElementById('phrase-editor');
        const toggler = document.getElementById('phrase-editor-toggle');
        if (divPhraseEditor) {
            removeListeners(toggler);
            enableDrag(divPhraseEditor);
            addPhraseControls();
            clearInterval(checkExist);
        }
    }, 200);
};
export const deletePhraseApp = () => {
    window.PHRASEAPP_ENABLED = false;
    const toggler = document.getElementById('phrase-editor-toggle');
    const divPhraseEditor = document.getElementById('phrase-editor');
    const phraseKeys = document.getElementById('phrase-app');
    if (toggler) {
        disableDrag(divPhraseEditor);
    }
    if (divPhraseEditor) {
        document.querySelector('.phraseAppCode').remove();
        divPhraseEditor.remove();
        document.removeEventListener('keydown', onKeyDown);
    }
    if (phraseKeys) {
        phraseKeys.remove();
    }
};
