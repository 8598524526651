import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { addPhraseApp, deletePhraseApp } from '@/quality/load-phraseapp';
export default defineComponent({
    setup() {
        const enabled = ref(true);
        const phraseButtonState = ref('');
        const onLoadPhrase = () => {
            const phraseAppEnabled = localStorage.getItem('phrase_enabled') === 'true';
            const vm = document.querySelector('#app')?.__vue__;
            if (!phraseAppEnabled) {
                localStorage.setItem('phrase_enabled', 'true');
                addPhraseApp();
                vm.$i18n.locale = false;
            }
            else {
                localStorage.setItem('phrase_enabled', 'false');
                vm.$i18n.locale = 'nl';
                deletePhraseApp();
                vm.$router.go('/');
            }
            enabled.value = false;
        };
        onMounted(() => {
            if (localStorage.phrase_enabled) {
                phraseButtonState.value =
                    localStorage.getItem('phrase_enabled') === 'true' ? 'Remove' : 'Load';
            }
        });
        return {
            enabled,
            phraseButtonState,
            onLoadPhrase
        };
    }
});
